<template>
  <div>
    <Navbar />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>

    <PageTitle
      :title="'Dashboard'"
      :subtitle="'All information about your cars'"
    />

    <div v-if="!isDataAvailable">
      <section class="hero is-small">
        <div class="hero-body">
          <div class="container">
            <div class="column">
              <figure class="image is-3by1">
                <img src="../assets/undraw_setup_analytics_8qkl.svg" alt />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column">
            <div class="card has-padding-20">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced">
                      Number of cars
                    </h3>
                    <h1 class="title">{{ numberOfCars }}</h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon">
                    <b-icon icon="car" size="is-large" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card has-padding-20">
              <div class="level is-mobile">
                <div class="level-item">
                  <div class="is-widget-label">
                    <h3 class="subtitle is-spaced">
                      Total car expenses
                    </h3>
                    <h1 class="title">{{ totalCarExpenses }} €</h1>
                  </div>
                </div>
                <div class="level-item has-widget-icon">
                  <div class="is-widget-icon">
                    <b-icon icon="cash" size="is-large" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="buttons flex-row justify-center">
      <b-button @click="goToCarExpenseForm" type="is-primary"
        >Add new car expense</b-button
      >
      <b-button type="is-primary" @click="openCarModal">Add new car</b-button>
    </div>

    <section class="section">
      <div class="container">
        <OverallExpensesChart />
      </div>
    </section>

    <CarsTableCard />

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { httpClient } from '@/api/httpClient.js'
import CarsTableCard from '@/components/CarsTableCard.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import OverallExpensesChart from '@/components/OverallExpensesChart.vue'
import CarModal from '@/components/CarModal.vue'

export default {
  name: 'Dashboard',
  components: {
    Navbar,
    CarsTableCard,
    Footer,
    PageTitle,
    OverallExpensesChart
  },
  data() {
    return {
      isLoading: false,
      selectedYear: false,
      cars: []
    }
  },
  computed: {
    isDataAvailable() {
      return this.cars.length > 0
    },
    totalCarExpenses() {
      return this.cars
        .reduce((total, car) => total + car.totalCost, 0)
        .toFixed(2)
    },
    numberOfCars() {
      return this.cars.length
    }
  },
  methods: {
    getCars() {
      this.isLoading = true

      httpClient
        .get('/cars')
        .then(res => {
          this.isLoading = false

          this.cars = res.data['hydra:member']
          if (this.cars.length === 0) {
            this.openCarModal()
          }
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    openCarModal() {
      this.$buefy.modal.open({
        parent: this,
        component: CarModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          carId: '0'
        },
        events: {
          success: () => {
            this.getCars()
          }
        }
      })
    },
    goToCarExpenseForm() {
      this.$router.push({ name: 'carExpenseForm' })
    }
  },
  created() {
    this.getCars()
  }
}
</script>

<style></style>
