<template>
  <section class="section">
    <div class="container">
      <b-button
        class="has-margin-bottom-20"
        type="is-primary"
        @click="openCarModal($event, 0)"
        >Add car</b-button
      >
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="car"></b-icon>
            Cars you manage
          </p>
        </header>
        <div class="card-content">
          <b-table
            :data="cars"
            :paginated="true"
            per-page="10"
            current-page.sync="1"
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            hoverable
            striped
            selectable
            @select="goToCarDetail"
          >
            <template slot-scope="props">
              <b-table-column field="id" label="ID" sortable numeric width="50">
                {{ props.row.id }}
              </b-table-column>

              <b-table-column
                field="licensePlate"
                label="License plate"
                sortable
                searchable
              >
                {{ props.row.licensePlate }}
              </b-table-column>

              <b-table-column field="make" label="Make" sortable searchable>
                {{ props.row.make }}
              </b-table-column>

              <b-table-column field="model" label="Model" sortable searchable>
                {{ props.row.model }}
              </b-table-column>

              <b-table-column
                field="year"
                label="Year"
                sortable
                numeric
                width="40"
              >
                {{ props.row.year }}
              </b-table-column>

              <b-table-column
                field="mileage"
                label="Mileage"
                sortable
                numeric
                centered
              >
                {{ props.row.mileage }}
              </b-table-column>

              <b-table-column
                field="totalCost"
                label="Total cost (€)"
                sortable
                numeric
                centered
              >
                {{ props.row.totalCost.toFixed(2) }} €
              </b-table-column>

              <b-table-column label="Last modified" sortable>
                <span class="tag is-success">
                  {{ new Date(props.row.updatedAt).toLocaleDateString() }}
                </span>
              </b-table-column>

              <b-table-column label="Actions">
                <button
                  class="button is-light"
                  @click.prevent="openCarModal($event, props.row.id)"
                >
                  <b-icon icon="pencil"></b-icon>
                </button>
                <button
                  class="button is-danger"
                  @click.prevent="confirmCarDelete($event, props.row.id)"
                >
                  <b-icon icon="delete"></b-icon>
                </button>
              </b-table-column>
            </template>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                  </p>
                  <p>No cars yet</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CarModal from '@/components/CarModal.vue'
import { httpClient } from '@/api/httpClient.js'

export default {
  name: 'CarsTableCard',
  data() {
    return {
      isLoading: false,
      cars: []
    }
  },
  created() {
    this.getCars()
  },
  methods: {
    getCars() {
      this.isLoading = true

      httpClient
        .get('/cars')
        .then(res => {
          this.isLoading = false

          this.cars = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    confirmCarDelete(event, id) {
      event.stopPropagation()

      this.$buefy.dialog.confirm({
        title: 'Delete car',
        message: 'Are you sure you want to delete this car?',
        confirmText: 'Delete car',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCar(id)
      })
    },
    deleteCar(id) {
      this.isLoading = true

      httpClient
        .delete(`/cars/${id}`)
        .then(() => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Car has been deleted.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
          this.getCars()
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    openCarModal(event, id) {
      event.stopPropagation()

      this.$buefy.modal.open({
        parent: this,
        component: CarModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          carId: id.toString()
        },
        events: {
          success: () => {
            this.getCars()
          }
        }
      })
    },
    goToCarDetail(item) {
      this.$router.push({ name: 'car', params: { carId: item.id.toString() } })
    }
  }
}
</script>

<style lang="scss" scoped></style>
